export const environment = {
  production: false,
  apiKey: "AIzaSyCP23T_mXjrO7o83FC3ByFaMQv-BFzR3OQ",
  firebase: {
    apiKey: "AIzaSyCP23T_mXjrO7o83FC3ByFaMQv-BFzR3OQ",
    authDomain: "lista-chwilowek.firebaseapp.com",
    databaseURL: "https://lista-chwilowek.firebaseio.com",
    projectId: "lista-chwilowek",
    storageBucket: "lista-chwilowek.appspot.com",
    messagingSenderId: "83655602194",
    appId: "1:83655602194:web:6ddcff11bf8c5573"
  },
  browser: true
};
import { Component } from "@angular/core";
import { GoogleAnalytics } from "@ionic-native/google-analytics/ngx";
import { PopoverController } from "@ionic/angular";
import { environment } from "../../environments/environment";
import { Loan } from "../models/loan.model";
import { AnalyticsService } from "../services/analytics.service";
import { RatingPopoverComponent } from "./rating-popover/rating-popover.component";

@Component({})
export class LoanBaseClass {
    public expanded: boolean = false;
    public browserEnvironment: boolean = true;
    public loan: Loan;

    constructor(
        public popoverController: PopoverController,
        public ga: GoogleAnalytics,
        public analyticsService: AnalyticsService
    ) {
        this.browserEnvironment = environment.browser;
    }

    onToggleMoreInfo(event): void {
        event.preventDefault();
        this.expanded = !this.expanded;
    }

    onRate() {
        this.presentRatingPopover(this.loan); 
    }

    async presentRatingPopover(loan: Loan) {
        const popover = await this.popoverController.create({
            component: RatingPopoverComponent,
            componentProps: loan,
            translucent: false,
            showBackdrop: true
        });
        return await popover.present();
    }

    getAverageRating(): number {
        if (!this.loan.userRates) {
            return 0;
        }
        const sum = this.loan.userRates
            .map(rating => rating.rate)
            .reduce((prev, curr) => prev + curr, 0);
        let average = sum / this.loan.userRates.length;
        return average;
    }

    onLoanSubmit(name: string) {
        this.analyticsService.trackEvent('loan-submit', name, new Date().toUTCString());
    }
}
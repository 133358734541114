export class Loan {
    constructor( 
        public name: string,
        public type: string,
        public imgUrl: string,
        public link: string,
        public characteristics: string[],
        public featuredRank: number,
        public userRates: {rate: number, deviceId: any}[]
    ) {}
}
import { Injectable } from '@angular/core';
import { GoogleAnalytics } from "@ionic-native/google-analytics/ngx";
import { environment } from '../../environments/environment';
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

    public browserEnvironment = environment.browser;

    constructor(
        public ga: GoogleAnalytics
    ) { }

    startTrackerWithId(id): Promise<any> {
        if (this.browserEnvironment) {
            gtag('config', id);
            return new Promise(resolve => resolve(true));
        } else {
            return this.ga.startTrackerWithId(id);
        }
    }

    trackView(pageUrl: string, screenName: string) {
        if (this.browserEnvironment) {
            gtag('set', 'page_path', pageUrl);
            gtag('event', 'page_view');
        } else {
            this.ga.trackView(screenName, pageUrl);
        }        
    }

    trackEvent(category, action, label?, value?) {
        if (this.browserEnvironment) {
            gtag('event', action, {
                'event_category': category,
                'event_label': label,
                'value': value
            });
        } else {
            this.ga.trackEvent(category, action, label, value);
        }
        
    }
}
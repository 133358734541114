import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss'],
})
export class BackofficeComponent implements OnInit {

  logged: boolean;

  constructor(
    private toastr: ToastrService,
    private angularFireAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.logged = false;
  }

  onLoginFormSubmit(formReference: NgForm): void {
    let login: string = formReference.form.value.login;
    let password: string = formReference.form.value.password;    
    this.angularFireAuth.signInWithEmailAndPassword(login, password).then(() => {
      this.logged = true;
    }).catch(error => {
      this.toastr.error("błędny login lub hasło");
      console.error(error);
    });
  }

  onLogout(): void {
    this.logged = false;
  }

}

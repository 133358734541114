import { Component, OnDestroy } from '@angular/core';
import { Platform, MenuController, NavController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { filter } from 'rxjs/operators';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
  public appPages = [
    {
      title: 'Chwilówki',
      url: '/chwilowki',
      icon: 'wallet'

    },
    {
      title: 'Na raty',
      url: '/na-raty',
      icon: 'calendar'
    },
    {
      title: 'Specjalne',
      url: '/specjalne',
      icon: 'star'
    },
    {
      title: 'FAQ - najczęstsze pytania',
      url: '/faq',
      icon: 'information-circle'
    }
  ];
  private subscriptions: Subscription[] = [];
  private lastTimeBackBtnPressed = 0;
  public browserEnvironment: boolean = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private router: Router,
    private navCtrl: NavController,
    private toast: ToastController,
    private angularFireAuth: AngularFireAuth,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private ga: GoogleAnalytics,
    private analyticsService: AnalyticsService
  ) {
    this.initializeApp();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onDownloadFromGooglePlay() {
    this.analyticsService.trackEvent('other-clicks', 'Google Play Download', new Date().toUTCString());
  }

  private initializeApp() {
    this.browserEnvironment = environment.browser;
    this.platform.ready().then(() => {
      this.startGoogleAnalyticsTracking();

      if (!this.browserEnvironment) {
        this.statusBar.styleDefault();
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#34ab01');
        this.splashScreen.hide();
      }
      this.handleBackButtonEvents();
      this.angularFireAuth.signInAnonymously().catch(error => console.error(error));

      if (this.browserEnvironment && !this.cookieService.check('cookiesAccepted')) {
        this.displayCookieToast();
      }
    });
  }

  private startGoogleAnalyticsTracking() {
    this.analyticsService.startTrackerWithId('UA-126755494-1').then(() => {
      this.startTrackingRouteChanges();
    }).catch(error => console.warn('Error starting GoogleAnalytics', error));
  }

  private startTrackingRouteChanges() {
    this.analyticsService.trackView('/chwilowki', 'chwilowki');
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event:NavigationStart) => {
        this.analyticsService.trackView(event.url, event.url.substr(1));
      });
  }

  private handleBackButtonEvents() {
    this.subscriptions.push(this.platform.backButton.subscribe(() => {
      this.menu.isOpen().then((result: Boolean) => {
        if (result) {
          this.menu.close();
          return;
        }
        if (this.router.url === '/chwilowki') {
          const maxTimeFromFirstClickToExit = 2000;
          if (new Date().getTime() - this.lastTimeBackBtnPressed < maxTimeFromFirstClickToExit) {
            navigator['app'].exitApp();
          } else {
            this.displayInfo('Wciśnij wstecz ponownie aby wyjść');
            this.lastTimeBackBtnPressed = new Date().getTime();
          }
        } else {
          this.navCtrl.navigateBack('/');
        }
      });
    }));
  }

  displayInfo(information: string) {
    this.toast.create({
      message: information,
      duration: 2000,
      position: 'middle',
      mode: "ios",
      cssClass: "backBtnToast"
    }).then((toast) => {
      toast.present();
    });
  }

  displayCookieToast() {
    let cookieToast = this.toastr.warning(
      "Zamykając ten komunikat (kliknięcie w przycisk 'X') i pozostając na tej stronie akceptujesz naszą <u><a href='./cookies'>politykę plików cookies</a></u>.",
      "Polityka cookies",
      {
        disableTimeOut: true,
        positionClass: "toast-bottom-right",
        closeButton: true,
        tapToDismiss: false,
        enableHtml: true
      }
    );

    cookieToast.onHidden.pipe(take(1)).subscribe(() => {
      this.cookieService.set('cookiesAccepted', 'true', 365);
    });
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentMonth: string;
  currentYear: number;

  constructor() {}

  ngOnInit() {
    this.initCurrentMonthAndYearVariables();
  }

  private initCurrentMonthAndYearVariables(): void {
    let monthIndex: number = new Date().getMonth();
    switch (monthIndex) {
      case 0: this.currentMonth = "styczeń"; break;
      case 1: this.currentMonth = "luty"; break;
      case 2: this.currentMonth = "marzec"; break;
      case 3: this.currentMonth = "kwiecień"; break;
      case 4: this.currentMonth = "maj"; break;
      case 5: this.currentMonth = "czerwiec"; break;
      case 6: this.currentMonth = "lipiec"; break;
      case 7: this.currentMonth = "sierpień"; break;
      case 8: this.currentMonth = "wrzesień"; break;
      case 9: this.currentMonth = "październik"; break;
      case 10: this.currentMonth = "listopad"; break;
      case 11: this.currentMonth = "grudzień"; break;
    };
    this.currentYear = new Date().getFullYear();
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { LoansService } from '../../services/loans.service';
import { Loan } from '../../models/loan.model';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-naraty',
  templateUrl: './naraty.component.html',
  styleUrls: ['./naraty.component.scss']
})
export class NaratyComponent implements OnInit {

  @ViewChild(IonContent, { static: true }) content: IonContent;

  loans: Loan[] = [];
  private subscriptions: Subscription[] = [];
  offsetTop: number;

  constructor(private LoansService: LoansService) { }

  ngOnInit() {
    this.initLoans();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((value) => value.unsubscribe());
  }

  onGoTop() {
    this.content.scrollToTop(1500);
  }

  onLogScrolling(event) {
    this.offsetTop = event.detail.scrollTop;
  }

  private initLoans(): void {
    this.subscriptions.push(this.LoansService.getLoansSubject().subscribe(loans => this.loans = loans));
  }

}

import { Component, Input } from '@angular/core';
import { Loan } from '../../models/loan.model';
import { PopoverController } from '@ionic/angular';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AnalyticsService } from '../../services/analytics.service';
import { LoanBaseClass } from '../LoanBaseClass.component';

@Component({
  selector: 'app-single-loan',
  templateUrl: './single-loan.component.html',
  styleUrls: ['./single-loan.component.scss']
})
export class SingleLoanComponent extends LoanBaseClass {

  @Input() loan: Loan;

  constructor(
    public popoverController: PopoverController,
    public ga: GoogleAnalytics,
    public analyticsService: AnalyticsService
  ) { 
    super(popoverController, ga, analyticsService);
  }
}

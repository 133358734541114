import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainComponent } from './subpages/main/main.component';
import { FaqComponent } from './subpages/faq/faq.component';
import { NaratyComponent } from './subpages/naraty/naraty.component';
import { SpecjalneComponent } from './subpages/specjalne/specjalne.component';
import { BackofficeComponent } from './subpages/backoffice/backoffice.component';
import { PolicyPrivacyComponent } from './subpages/policy-privacy/policy-privacy.component';
import { SupportComponent } from './subpages/support/support.component';
import { CookiesComponent } from './subpages/cookies/cookies.component';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'chwilowki',
    pathMatch: 'full' 
  },
  { 
    path: 'chwilowki', 
    component: MainComponent 
  },
  {
    path: 'na-raty',
    component: NaratyComponent
  },
  {
    path: 'specjalne',
    component: SpecjalneComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'backoffice',
    component: BackofficeComponent
  },
  {
    path: 'polityka-prywatnosci',
    component: PolicyPrivacyComponent
  },
  {
    path: 'wsparcie',
    component: SupportComponent
  },
  {
    path: 'cookies',
    component: CookiesComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

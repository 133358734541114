import { Component } from '@angular/core';
import { NavParams, ToastController } from '@ionic/angular';
import { Loan } from 'src/app/models/loan.model';
import { LoansService } from 'src/app/services/loans.service';

@Component({
  selector: 'app-rating-popover',
  templateUrl: './rating-popover.component.html',
  styleUrls: ['./rating-popover.component.scss']
})
export class RatingPopoverComponent {

  filledStars: number = 0;
  emptyStars: number = 5;
  loan: Loan;
  popover: any;

  constructor(
    public navParams: NavParams,
    private loansService: LoansService,
    private toast: ToastController
  ) {
    this.loan = <Loan>this.navParams.data;
  }

  onEmptyClick(index: number) {
    this.filledStars += index + 1;
    this.emptyStars -= index + 1;
  }

  onFilledClick(index: number) {
    this.filledStars = index + 1;
    this.emptyStars = 5 - this.filledStars;
  }

  onConfirm() {
    if (this.filledStars === 0) {
      return this.displayError("Zaznacz odpowiednią liczbę gwiazdek");
    }
    this.loansService.rate(this.loan, this.filledStars).then(() => {
      this.displaySuccess("Pomyślnie dodano ocenę");
      this.popover.dismiss();
    }).catch(error => {
      if (error === "UNIQUE_ID") {
        this.displayError("Wystąpił błąd w trakcie dodawania oceny - spróbuj ponownie później");
      } else if (error === "ALREADY_VOTED") {
        this.displayError("Każdą pożyczkę można ocenić tylko raz");
      }      
    });
  }

  onCancel() {
    this.popover.dismiss();
  }

  getIterableForNgFor(repeatCout: number): number[] {
    let resultArray = [];
    for (let i = 0; i < repeatCout; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  private displayError(information: string) {
    this.toast.create({
      message: information,
      duration: 5000,
      position: "middle",
      mode: "ios",
      buttons: [
        {
          text: "Zamknij",
          role: "cancel"
        }
      ],
      color: "danger"
    }).then((toast) => {
      toast.present();
    });
  }

  private displaySuccess(information: string) {
    this.toast.create({
      message: information,
      duration: 2000,
      position: 'middle',
      mode: "ios",
      color: "success"
    }).then((toast) => {
      toast.present();
    });
  }

}
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoansService } from './services/loans.service';
import { MainComponent } from './subpages/main/main.component';
import { SingleLoanComponent } from './fragments/single-loan/single-loan.component';
import { FeaturedLoanComponent } from './fragments/featured-loan/featured-loan.component';
import { TypePipe } from './pipes/type.pipe';
import { RankPipe } from './pipes/rank.pipe';
import { HeaderComponent } from './fragments/header/header.component';
import { FooterComponent } from './fragments/footer/footer.component';
import { HeaderLogoComponent } from './fragments/header-logo/header-logo.component';
import { IonFixedFooterComponent } from './fragments/ion-fixed-footer/ion-fixed-footer.component';
import { StarsRatingComponent } from './fragments/stars-rating/stars-rating.component';
import { FaqComponent } from './subpages/faq/faq.component';
import { NaratyComponent } from './subpages/naraty/naraty.component';
import { SpecjalneComponent } from './subpages/specjalne/specjalne.component';
import { HttpClientModule } from '@angular/common/http';
import { RatingPopoverComponent } from './fragments/rating-popover/rating-popover.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoadingIndicatorComponent } from './fragments/loading-indicator/loading-indicator.component';
import { BackofficeComponent } from './subpages/backoffice/backoffice.component';
import { FormsModule } from '@angular/forms';
import { LoansComponent } from './subpages/backoffice/loans/loans.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PolicyPrivacyComponent } from './subpages/policy-privacy/policy-privacy.component';
import { CookiesComponent } from './subpages/cookies/cookies.component';
import { SupportComponent } from './subpages/support/support.component';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AnalyticsService } from './services/analytics.service';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SingleLoanComponent,
    FeaturedLoanComponent,
    TypePipe,
    RankPipe,
    HeaderComponent,
    FooterComponent,
    FaqComponent,
    NaratyComponent,
    SpecjalneComponent,
    HeaderLogoComponent,
    IonFixedFooterComponent,
    StarsRatingComponent,
    RatingPopoverComponent,
    LoadingIndicatorComponent,
    BackofficeComponent,
    LoansComponent,
    PolicyPrivacyComponent,
    CookiesComponent,
    SupportComponent
  ],
  entryComponents: [
    RatingPopoverComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoansService,
    AngularFireDatabase,
    AngularFireAuth,
    CookieService,
    GoogleAnalytics,
    AnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

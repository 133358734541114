import { Component, OnInit } from '@angular/core';
import { LoansService } from '../../services/loans.service';
import { Loan } from '../../models/loan.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-specjalne',
  templateUrl: './specjalne.component.html',
  styleUrls: ['./specjalne.component.css']
})
export class SpecjalneComponent implements OnInit {

  loans: Loan[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private LoansService: LoansService) { }

  ngOnInit() {
    this.initLoans();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((value) => value.unsubscribe());
  }

  private initLoans(): void {
    this.subscriptions.push(this.LoansService.getLoansSubject().subscribe(loans => this.loans = loans));
  }

}

import { Injectable } from '@angular/core';
import { Loan } from '../models/loan.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class LoansService {

    private loansSubject: BehaviorSubject<Loan[]> = new BehaviorSubject([]);
    private subscriptions: Subscription[] = [];
    private loansRef = this.db.list<Loan>('all');
    private loansWithKeys: { key: any, val: Loan }[];

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private angularFireAuth: AngularFireAuth
    ) {
        this.isLoggedIn().then(() => {
            this.retrieveLoans();  
        });        
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((value) => value.unsubscribe());
    }

    getLoansSubject(): BehaviorSubject<Loan[]> {
        return this.loansSubject;
    }

    rate(loan: Loan, rating: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const loanKey = this.getKeyForLoan(loan);
            const currentRates = this.getCurrentRatesForKey(loanKey);

            this.getIpAddress().then((ip: string) => {
                if (this.didUserAlreadyRated(loanKey, ip)) {
                    return reject("ALREADY_VOTED");
                }
                currentRates.push({ rate: rating, deviceId: ip });
                this.loansRef.update(loanKey, <Loan><unknown>{ userRates: currentRates });
                resolve("SUCCESS");
            }).catch(error => {
                console.error(error)
                reject("UNIQUE_ID");
            });       
        });        
    }

    overvriteAllLoans(loans: Loan[]): void {
        this.db.object("all").set(loans);
    }

    private isLoggedIn() {
        return new Promise((resolve, reject) => {
            let subscription = this.angularFireAuth.authState.subscribe(user => {
                if (user) {
                    subscription.unsubscribe();
                    resolve(true);
                }
            });
        });
    }

    private getIpAddress(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.http.get('https://ipapi.co/json/').pipe(take(1)).subscribe((result: any) => {
                resolve(result.ip);
            })
        })
    }

    private getKeyForLoan(loan: Loan) {
        let foundLoan = this.loansWithKeys.filter(loanWithKey => {
            return loanWithKey.val.name === loan.name &&
                loanWithKey.val.type === loan.type &&
                loanWithKey.val.link === loan.link;
        });
        return foundLoan[0].key;
    }

    private getCurrentRatesForKey(key) {
        return this.loansWithKeys.filter(loanWithKey => loanWithKey.key === key)[0].val.userRates || [];
    }

    private didUserAlreadyRated(loanKey, id): boolean {
        let userRates = this.loansWithKeys.filter(loanWithKey => loanWithKey.key === loanKey)[0].val.userRates || [];
        return userRates.filter((rates: {deviceId, rate}) => rates.deviceId == id).length > 0;
    }

    private retrieveLoans() {
        this.subscriptions.push(this.loansRef.snapshotChanges().subscribe(
            changes => {
                let loans = changes.map(c => c.payload.val());
                this.loansSubject.next(loans);
                this.loansWithKeys = changes.map(c => { 
                    return {key: c.payload.key, val: c.payload.val()} 
                });
            },
            error => {
                console.error(error);
            }
        ));
    }

}

import { Pipe, PipeTransform } from '@angular/core';
import { Loan } from '../models/loan.model';

@Pipe({
  name: 'rank'
})
export class RankPipe implements PipeTransform {

  transform(loans: Loan[], rank: number = 0): any {
    return loans.filter((loan) => loan.featuredRank === rank);
  }

}

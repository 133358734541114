import { Component, OnInit } from '@angular/core';
import { Loan } from 'src/app/models/loan.model';
import { Subscription } from 'rxjs';
import { LoansService } from 'src/app/services/loans.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-loans',
  templateUrl: './loans.component.html',
  styleUrls: ['./loans.component.scss'],
})
export class LoansComponent implements OnInit {

  loans: Loan[] = [];
  private subscriptions: Subscription[] = [];
  loanToUpdateIndex: number = null;
  backupLoanObject: Loan = null; // copy for reset button purpose
  newLoanFormVisible: boolean = false;
  newLoan: Loan = null;

  constructor(
    private loansService: LoansService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.initLoans();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((value) => value.unsubscribe());
  }

  onEdit(index: number): void {
    this.loanToUpdateIndex = index;
    this.backupLoanObject = JSON.parse(JSON.stringify(this.loans[this.loanToUpdateIndex]));
  }

  onDelete(index: number): void {
    this.loans.splice(index, 1);
    this.loansService.overvriteAllLoans(this.loans);
    this.toastr.success("pożyczka została usunięta z listy");
  }

  onEditFormSubmit(formRef: NgForm): void {
    this.loans[this.loanToUpdateIndex].featuredRank = +this.loans[this.loanToUpdateIndex].featuredRank;
    this.loansService.overvriteAllLoans(this.loans);
    this.backupLoanObject = JSON.parse(JSON.stringify(this.loans[this.loanToUpdateIndex]));
    this.toastr.success("zmiany zostały zapisane pomyślnie");
  }

  onAddFormSubmit(formRef: NgForm): void {
    this.newLoan.featuredRank = +this.newLoan.featuredRank;
    this.loans.push(this.newLoan);
    this.loansService.overvriteAllLoans(this.loans);
    this.toastr.success("nowa pożyczka została dodana do listy");
  }

  onResetEditForm(): void {
    this.loans[this.loanToUpdateIndex] = JSON.parse(JSON.stringify(this.backupLoanObject));
    this.loanToUpdateIndex = null;
    this.backupLoanObject = null;
  }

  onAddCharacteristic(): void {
    this.loans[this.loanToUpdateIndex].characteristics.push("");
  }

  onRemoveCharacteristic(): void {
    this.loans[this.loanToUpdateIndex].characteristics.pop();
  }

  onAddCharacteristicToNewLoan(): void {
    this.newLoan.characteristics.push("");
  }

  onRemoveCharacteristicToNewLoan(): void {
    this.newLoan.characteristics.pop();
  }

  onAddNew(): void {
    this.newLoanFormVisible = true;
    this.newLoan = this.getEmptyLoanObject();
  }

  onResetAddForm(): void {
    this.newLoan = this.getEmptyLoanObject();
    this.newLoanFormVisible = false;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onAddRating(rating): void {
    const rate = {
      deviceId: "admin",
      rate: rating
    };
    this.loans[this.loanToUpdateIndex].userRates.push(rate);
  }

  getRating(index: number): number {
    let loanIndex = this.loanToUpdateIndex;
    if (index !== undefined) {
      loanIndex = index;
    }
    if (!this.loans[loanIndex].userRates) {
      this.loans[loanIndex].userRates = [];
    }
    const ratingsCount = this.loans[loanIndex].userRates.length;
    if (ratingsCount === 0) {
      return 0;
    }
    const ratingSum = this.loans[loanIndex].userRates
      .map(rating => rating.rate)
      .reduce((prev, curr) => prev + curr, 0);
    const ratingAverage = ratingSum / ratingsCount;
    return Math.round(ratingAverage * 100) / 100;
  }

  private initLoans(): void {
    this.subscriptions.push(this.loansService.getLoansSubject().subscribe(loans => this.loans = loans));
  }

  private getEmptyLoanObject(): Loan {
    return {
      characteristics: [""],
      featuredRank: 0,
      imgUrl: "",
      link: "",
      name: "",
      type: "chwilówki",
      userRates: []
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.scss']
})
export class StarsRatingComponent implements OnInit {

  @Input() rate: number;
  filledStars: number;
  halfStars: number;
  emptyStars: number;

  constructor(
  ) {}

  ngOnInit(): void {
    this.indicateStarsCount();
  }

  getIterableForNgFor(repeatCout: number): number[] {
    let resultArray = [];
    for (let i = 0; i < repeatCout; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  private indicateStarsCount() {
    if (this.rate === 0) {
      return 0;
    }
    const rateRoundedToNearestHalf = this.roundToNearestHalf(this.rate);
    this.filledStars = Math.floor(rateRoundedToNearestHalf);
    this.emptyStars = Math.floor(5 - rateRoundedToNearestHalf);
    this.halfStars = this.isInt(rateRoundedToNearestHalf) ? 0 : 1;
  }

  private roundToNearestHalf(num: number) {
    return Math.round(num * 2) / 2;
  }

  private isInt(n) {
    return n % 1 === 0;
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoansService } from '../../services/loans.service';
import { Loan } from '../../models/loan.model';
import { Subscription } from 'rxjs';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  @ViewChild(IonContent, { static: true }) content: IonContent;

  loans: Loan[] = [];
  offsetTop: number;
  private subscriptions: Subscription[] = [];
  
  constructor(
    private LoansService: LoansService
  ) { }

  ngOnInit() {
    this.initLoans();
  }

  onGoTop() {
    this.content.scrollToTop(1500);
  }

  onLogScrolling(event) {
    this.offsetTop = event.detail.scrollTop;
  }

  private initLoans() {
    this.subscriptions.push(this.LoansService.getLoansSubject().subscribe(loans => {
      this.loans = loans;
    }));
  }
  
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-ion-fixed-footer',
  templateUrl: './ion-fixed-footer.component.html',
  styleUrls: ['./ion-fixed-footer.component.scss']
})
export class IonFixedFooterComponent {

  public browserEnvironment: boolean = environment.browser;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService
  ) { }

  onLogoClick() {
    this.router.navigate(["/"]);
  }

  onFacebookLink() {
    this.analyticsService.trackEvent('other-clicks', 'Facebook link', new Date().toUTCString());
  }

}
import { Pipe, PipeTransform } from '@angular/core';
import { Loan } from '../models/loan.model';

@Pipe({
  name: 'type'
})
export class TypePipe implements PipeTransform {

  transform(loans: Loan[], type: string): any {
    return loans.filter((loan) => loan.type === type);
  }

}

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  constructor(
    private cookieService: CookieService
  ) { }

  ngOnInit() {
  }

  onAcceptCookiesAndRedirect() {
    this.cookieService.set('cookiesAccepted', 'true', 365);
    location.href = "./chwilowki";
  }

}
